import React from "react";
import "../styles/pages/servicespage.css";

const ServicesPage = () => {
  const whatsappNumber = "+905432896941";

  const packages = [
    {
      title: "Standart Web Tasarım Paketi",
      price: "10000 TL",
      description: [
        "Temel seviyede profesyonel bir web varlığı için uygun.",
        "Temel sayfa yapıları ve özelleştirilmiş tasarım",
        "Size özel tasarım",
        "Mobil uyumlu tasarım",
        "Yönetim paneli",
        "Sınırsız sayfa ekleme",
        "Alan adı ve hosting (ilk yıl ücretsiz)",
        "SSL Güvenlik Sertifikası",
        "SEO uyumlu tasarım",
        "Sosyal medya uyumu",
        "Aylık 4 yedekleme ve telefonla destek",
      ],
      delivery: "Teslimat Süresi: 5-7 iş günü",
    },
    {
      title: "Gelişmiş Plus Web Tasarım Paketi",
      price: "18.000 TL",
      description: [
        "Orta ölçekli işletmeler için uygun, daha kapsamlı bir çözüm.",
        "Genişletilmiş sayfa yapıları ve özelleştirilmiş tasarım",
        "3 kurumsal mail adresi",
        "Çoklu dil desteği",
        "Google Ads reklam kurulumu",
        "Mobil ve tablet uyumlu tasarım",
        "Gelişmiş yönetim paneli",
        "Alan adı ve hosting (ilk yıl ücretsiz)",
        "Gelişmiş SEO optimizasyonu",
        "Aylık 8 yedekleme ve gelişmiş destek",
      ],
      delivery: "Teslimat Süresi: 7-10 gün",
    },
    {
      title: "Giriş E-Ticaret Paketi",
      price: "35.000 TL",
      description: [
        "Küçük ve orta ölçekli işletmeler için temel e-ticaret çözümü.",
        "Anahtar teslim e-ticaret tasarımı",
        "Sınırsız ürün ve kategori ekleme",
        "10 kurumsal mail adresi",
        "Çoklu dil ve para birimi desteği",
        "Entegre ödeme sistemleri",
        "Kargo ve teslimat yönetimi",
        "Gelişmiş yönetim paneli",
        "Alan adı ve hosting (ilk yıl ücretsiz)",
        "Aylık 12 yedekleme ve teknik destek",
      ],
      delivery: "Teslimat Süresi: 10-15 gün",
    },
    {
      title: "Gelişmiş E-Ticaret Paketi",
      price: "İletişime geçiniz",
      description: [
        "Büyük ölçekli işletmeler için tamamen özelleştirilebilir e-ticaret çözümü.",
        "Tamamen özelleştirilebilir tasarım",
        "Sınırsız ürün, kategori ve varyant ekleme",
        "20 kurumsal mail adresi",
        "Gelişmiş ödeme sistemleri",
        "Gelişmiş kargo ve teslimat yönetimi",
        "Gelişmiş yönetim paneli ve CRM",
        "Gelişmiş güvenlik ve SSL sertifikası",
        "Gelişmiş SEO ve dijital pazarlama entegrasyonu",
        "Günlük yedekleme ve 24/7 teknik destek",
      ],
      delivery: "Teslimat Süresi: 1 ay",
    },
  ];

  // WhatsApp yönlendirme fonksiyonu
  const handleContactClick = (title) => {
    const message = `Merhaba, ${title} hakkında bilgi almak istiyorum.`;
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div className="main-container"> {/* Container Yapısı Eklendi */}
      <div className="services-page">
        <div className="page-header">
          <h1 className="page-title">Hizmetlerimiz</h1>
        </div>

        <div className="packages-grid">
          {packages.map((pkg, index) => (
            <div
              key={index}
              className="package-card"
            >
              <h2 className="package-title">{pkg.title}</h2>
              <h3 className="package-price">{pkg.price}</h3>
              <ul className="package-description">
                {pkg.description.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
              <p className="package-delivery">{pkg.delivery}</p>
              <button
                className="contact-button"
                onClick={(e) => {
                  e.stopPropagation(); // Üstteki div'in tıklama olayını durdurur
                  handleContactClick(pkg.title);
                }}
              >
                İletişime Geç
              </button>
            </div>
          ))}
        </div>

        {/* Tanıtım Bölümü */}
        <div className="promotion-section">
          <h2>İhtiyaçlarınıza Yönelik Özel ve Güvenilir Çözümler</h2>
          <p>
            Şirketimiz, farklı sektörlerin ve işletme türlerinin gereksinimlerine
            uygun, özelleştirilebilir çözümler sunar ve projelerinizi etkili bir
            şekilde hayata geçirmenize yardımcı olmayı amaçlar. Web tasarımı,
            e-ticaret çözümleri, SEO optimizasyonu ve dijital pazarlama
            stratejileri gibi geniş bir yelpazede hizmet sunuyoruz. Her proje,
            ihtiyaçlarınıza göre titizlikle tasarlanır ve en yüksek kalitede
            hizmet sağlanır.
          </p>
          <p>
            Projelerimizin başlangıcında, sizinle detaylı bir görüşme yaparak
            gereksinimlerinizi ve hedeflerinizi tam olarak anlamaya çalışırız. Bu
            analiz sürecinin ardından, size uygun çözüm önerilerini sunarak
            projenin temellerini oluştururuz. Bu sayede, projenin her aşamasında
            karşılıklı bir anlayış ve iş birliği ortamı sağlanır.
          </p>
          <p>
            Geliştirme süreci boyunca ilerlemenin her aşamasını sizinle paylaşır,
            düzenli geri bildirimler alır ve projenin sorunsuz ilerlemesini
            sağlarız. Kullanıcı dostu bir tasarım ve etkili bir arayüz oluşturma
            sürecinde, adım adım ilerleriz. İlerleme raporları ve geri bildirim
            toplantıları ile projenizi yakından takip edebilmenize imkan tanırız.
          </p>
          <p>
            Proje tamamlandıktan sonra, gerekli revizyonları yaparak isteğinize
            uygun bir çözüm sunarız. Revizyon taleplerinizi elimizden geldiğince
            karşılamaya çalışır ve memnuniyetinizi önceliğimiz haline getiririz.
            Süreç boyunca esnek ve müşteri odaklı bir yaklaşım benimsediğimiz
            için, hedeflerinize ulaşmanızda destek olmaya devam ederiz.
          </p>
          <p>
            İhtiyacınıza uygun hizmetler almak, işinizin değerini artırmak ve daha
            fazla bilgi edinmek için bizimle iletişime geçebilirsiniz. Size etkili
            ve güvenilir çözümler sunmak için buradayız.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
