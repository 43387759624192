import React from 'react';
import '../styles/contactIcon.css';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa'; // react-icons'dan ikonları aldık

const ContactIcon = () => {
  const phoneNumber = '+905432896941';

  return (
    <div className="contact-icon-container">
      <div
        className="contact-icon-button"
        onClick={() => window.location.href = `tel:${phoneNumber}`}
      >
        <FaPhoneAlt className="contact-icon phone-icon" />
      </div>
      <div
        className="contact-icon-button"
        onClick={() => window.location.href = `https://wa.me/${phoneNumber.replace(/\s/g, '')}`}
      >
        <FaWhatsapp className="contact-icon whatsapp-icon" />
      </div>
    </div>
  );
};

export default ContactIcon;
