import React from 'react';
import '../styles/pages/about.css';
import '../styles/pages/pagesHeaderTag.css'; // Ortak başlık stilleri için

const About = () => {
  return (
    <div className="page about-page">
      <h1 className="about-heading">Hakkımızda</h1>
      <div className="about-content">
        
        <h2>CertisCode'a Hoş Geldiniz</h2>
        <p>
          <strong>CertisCode</strong>, müşteri memnuniyetini merkeze alan bir yazılım geliştirme ekibidir.
          Her projede güven, kalite ve estetik tasarım ilkelerini birleştirerek, müşterilerimizin ihtiyaçlarına
          uygun çözümler sunuyoruz. İletişimimizde açık, net ve şeffaf bir yaklaşım benimseyerek,
          müşterilerimizin projelerinin her aşamasında tam bilgi sahibi olmalarını sağlıyoruz.
        </p>

        <h2>Güven ve İşbirliği</h2>
        <p>
          Başarının temel taşlarından biri olan güveni, iş süreçlerimizin odak noktası haline getirdik.
          Müşterilerimizle sağlıklı bir işbirliği kurarak, projelerinde tam kontrol ve şeffaflık sağlıyoruz.
          İş ortaklarımıza sadece yazılım çözümleri değil, aynı zamanda güçlü bir iş ortaklığı deneyimi sunuyoruz.
        </p>

        <h2>Esneklik ve Revizyon Hakları</h2>
        <p>
          Projelerimizin her aşamasında müşteri geri bildirimlerini önemsiyor ve revizyon taleplerini sınırsız bir
          şekilde karşılıyoruz. İhtiyaçlarınıza tam uyum sağlayan tasarımlar oluşturmak ve her zaman memnuniyetinizi
          en üst seviyede tutmak için buradayız.
        </p>

        <h2>Modern ve Kullanıcı Dostu Tasarım</h2>
        <p>
          CertisCode, modern ve kullanıcı dostu web tasarımlarına odaklanır. Mobil uyumlu tasarımlarımız, hızlı
          yükleme süreleri ve SEO dostu yapılarla desteklenir. İhtiyaçlarınıza göre özelleştirilmiş çözümler sunarak
          markanızı dijital dünyada daha görünür hale getiriyoruz.
        </p>

        <h2>Uzun Vadeli Vizyon</h2>
        <p>
          CertisCode olarak, yalnızca projelerinizi hayata geçirmekle kalmıyor, aynı zamanda uzun vadeli başarıyı
          hedefliyoruz. Yenilikçi yaklaşımlar ve sürekli gelişimle, her projenin daha iyiye gitmesini sağlamak için
          yanınızdayız.
        </p>

      </div>
    </div>
  );
};

export default About;
