import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

const Header = () => {
  const [siteSettings, setSiteSettings] = useState(null);

  useEffect(() => {
    const fetchSiteSettings = async () => {
      try {
        const response = await axios.get('https://www.certiscode.com/api/sitesettings/');
        if (response.data && response.data.length > 0) {
          setSiteSettings(response.data[0]);
        }
      } catch (error) {
        console.error('Site ayarları alınırken hata oluştu:', error);
      }
    };

    fetchSiteSettings();
  }, []);

  if (!siteSettings) return null;

  // Favicon için tam URL oluşturma
  const faviconUrl = `https://www.certiscode.com/${siteSettings.favicon}`;

  return (
    <Helmet>
      <title>{siteSettings.site_title || 'CertisCode'}</title>
      <link rel="icon" href={faviconUrl} type="image/x-icon" />
      <meta name="description" content={siteSettings.site_description || 'Varsayılan açıklama'} />
      <meta name="keywords" content={siteSettings.keywords || 'anahtar kelime 1, anahtar kelime 2'} />
      {/* Eklenen meta etiketleri */}
      <meta property="og:title" content={siteSettings.site_title || 'CertisCode'} />
      <meta property="og:description" content={siteSettings.site_description || 'Varsayılan açıklama'} />
      <meta property="og:image" content={faviconUrl} />
    </Helmet>
  );
};

export default Header;
