import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/pages/references.css";
import "../styles/pages/pagesHeaderTag.css";

const References = () => {
  const [references, setReferences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReferences = async () => {
      try {
        const response = await axios.get("https://www.certiscode.com/api/proje-yukleme/");
        setReferences(response.data);
        setLoading(false);
      } catch (err) {
        setError("Veri yüklenirken bir hata oluştu.");
        setLoading(false);
      }
    };

    fetchReferences();
  }, []);

  if (loading) {
    return <div className="loading">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="references-page">
      <h1>Referanslarımız</h1>
      <div className="reference-cards-container">
        {references.map((reference) => (
          <div key={reference.id} className="reference-card">
            <a
              href={reference.project_url}
              target="_blank"
              rel="noopener noreferrer"
              className="reference-link"
            >
              <h3>{reference.name}</h3>
              <img
                src={reference.thumbnail}
                alt={reference.name}
                className="reference-image"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default References;
