import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Swiper CSS
import "swiper/css/pagination";
import "swiper/css/autoplay";

import axios from "axios";

// Swiper modülleri
import { Pagination, Autoplay } from "swiper/modules";

// CSS dosyası
import "../styles/slider.css";

const ImageSlider = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://www.certiscode.com/api/imageslider/');
        setImages(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching images:", err);
        setError("Görüntüler yüklenirken bir hata oluştu.");
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return <div className="loading">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="image-slider-container">
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={0} /* Kaydırma aralığını minimuma indirin */
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        loop={true}
      >
        {images.map((image) => (
          <SwiperSlide key={image.id}>
            <img
              src={image.image}
              alt={image.alt_text}
              className="slider-image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSlider;
