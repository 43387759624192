import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    // SiteSettings API'sinden logo verisini çekmek için API çağrısı
    const fetchSiteSettings = async () => {
      try {
        const response = await axios.get('https://www.certiscode.com/api/sitesettings/');
        if (response.data && response.data.length > 0) {
          setLogoUrl(response.data[0].logo);  // Logo URL'sini ayarla
        }
      } catch (error) {
        console.error('Site ayarları alınırken hata oluştu:', error);
      }
    };

    fetchSiteSettings();
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      {/* Logo Bölümü */}
      <div className="navbar-brand">
        <Link to="/" className="logo">
          {logoUrl ? (
            <img src={logoUrl} alt="CertisCode Logo" className="logo-image" />  
          ) : (
            <span> </span> 
          )}
        </Link>
      </div>

      {/* Masaüstü Menü */}
      <div className="navbar-menu-container">
        <ul className="desktop-navbar-menu">
          <li><Link to="/">Ana Sayfa</Link></li>
          <li><Link to="/hakkimizda">Hakkımızda</Link></li>
          <li><Link to="/hizmetler">Hizmetler</Link></li>
          <li><Link to="/referanslar">Referanslarımız</Link></li>
          <li><Link to="/iletisim">İletişim</Link></li>
        </ul>
      </div>

      {/* Hamburger Menü */}
      <div
        className={`burger-menu ${menuOpen ? 'burger-menu-open' : ''}`}
        onClick={toggleMenu}
      >
        <span>&#9776;</span>
      </div>

      {/* Overlay */}
      <div
        className={`overlay ${menuOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      ></div>

      {/* Mobil Menü */}
      <div className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/" onClick={toggleMenu}>Ana Sayfa</Link></li>
          <li><Link to="/hakkimizda" onClick={toggleMenu}>Hakkımızda</Link></li>
          <li><Link to="/hizmetler" onClick={toggleMenu}>Hizmetler</Link></li>
          <li><Link to="/referanslar" onClick={toggleMenu}>Referanslarımız</Link></li>
          <li><Link to="/iletisim" onClick={toggleMenu}>İletişim</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
