import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import SEO from "./components/SEO";
import AnimatedSection from "./components/AnimatedSection";

import AdminPage from "./components/AdminPage";
import "./styles/global.css";
import "./styles/navbar.css";
import "./styles/services.css";
import "./styles/footer.css";
import "./styles/slider.css";

import InfoBar from "./components/InfoBar";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import Footer from "./components/Footer";
import ImageSlider from "./components/ImageSlider";
import Header from "./components/Header";
import ContactIcon from "./components/ContactIcon";
import MainParagraf from "./components/MainParagraf";
import About from "./pages/About";
import ServicesPage from "./pages/ServicesPage";
import References from "./pages/References";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/privacyPolicy";

const contactInfo = {
  phone_number: "+905432896941", // Telefon numarası
};

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Header />
        <InfoBar />
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <SEO pageName="home" />
                <AnimatedSection>
                  <ImageSlider />
                </AnimatedSection>
                <AnimatedSection>
                  <MainParagraf />
                </AnimatedSection>
                <AnimatedSection>
                  <Services />
                </AnimatedSection>
              </>
            }
          />
          <Route
            path="/admin"
            element={
              <AnimatedSection>
                <SEO pageName="admin" />
                <AdminPage />
              </AnimatedSection>
            }
          />
          <Route
            path="/hakkimizda"
            element={
              <AnimatedSection>
                <SEO pageName="hakkimizda" />
                <About />
              </AnimatedSection>
            }
          />
          <Route
            path="/hizmetler"
            element={
              <AnimatedSection>
                <SEO pageName="hizmetler" />
                <ServicesPage />
              </AnimatedSection>
            }
          />
          <Route
            path="/referanslar"
            element={
              <AnimatedSection>
                <SEO pageName="referanslar" />
                <References />
              </AnimatedSection>
            }
          />
          <Route
            path="/iletisim"
            element={
              <AnimatedSection>
                <SEO pageName="iletisim" />
                <Contact />
              </AnimatedSection>
            }
          />
          <Route
            path="/gizlilik-ve-cerez-politikasi"
            element={
              <AnimatedSection>
                <SEO pageName="privacy-policy" />
                <PrivacyPolicy />
              </AnimatedSection>
            }
          />
        </Routes>
        <ContactIcon contactInfo={contactInfo} />
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
