import React from 'react';
import '../styles/pages/privacyPolicy.css'; // Gizlilik politikası için özel CSS

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Gizlilik ve Çerez Politikası</h1>
      <p>
        CertisCode olarak, kullanıcılarımızın gizliliğine büyük önem veriyoruz. Bu gizlilik politikası, web sitemizi ziyaret ettiğinizde ve hizmetlerimizi kullandığınızda hangi verileri topladığımızı, nasıl kullandığımızı ve nasıl koruduğumuzu açıklamaktadır.
      </p>

      <h2>1. Toplanan Veriler</h2>
      <p>
        Web sitemizi ziyaret ettiğinizde ve hizmetlerimizi kullandığınızda, aşağıdaki veriler toplanabilir:
      </p>
      <ul>
        <li>
          <strong>Kişisel Bilgiler:</strong> Ad, soyad, e-posta adresi, telefon numarası gibi, kullanıcının kendi isteğiyle sağladığı bilgiler.
        </li>
        <li>
          <strong>Otomatik Olarak Toplanan Veriler:</strong> IP adresi, tarayıcı türü, cihaz türü, işletim sistemi gibi teknik bilgiler. Bu veriler, kullanıcı deneyimini geliştirmek ve web sitemizin performansını artırmak amacıyla kullanılır.
        </li>
        <li>
          <strong>Çerezler:</strong> Web sitemiz, kullanıcı deneyimini optimize etmek ve belirli özellikleri sağlamak için çerezler kullanır. Çerezler, tarayıcı üzerinden bilgisayarınıza kaydedilen küçük metin dosyalarıdır ve kullanıcıların web sitemizdeki tercihlerine göre özelleştirilmiş bir deneyim sunar.
        </li>
      </ul>

      <h2>2. Verilerin Kullanımı</h2>
      <p>
        Toplanan veriler şu amaçlarla kullanılabilir:
      </p>
      <ul>
        <li>Hizmetlerimizi sağlamak ve geliştirmek,</li>
        <li>Kullanıcı deneyimini iyileştirmek,</li>
        <li>Kullanıcılara özel içerikler ve teklifler sunmak,</li>
        <li>Yasal yükümlülüklere uymak ve dolandırıcılığa karşı önlem almak.</li>
      </ul>

      <h2>3. Verilerin Korunması</h2>
      <p>
        Verilerinizin gizliliği ve güvenliği bizim için çok önemlidir. Bu nedenle, uygun teknik ve idari güvenlik önlemleri alarak verilerinizi koruyoruz. Ancak, internet üzerinden veri iletiminin hiçbir zaman %100 güvenli olmadığına dikkat çekmek isteriz.
      </p>

      <h2>4. Üçüncü Taraf Hizmet Sağlayıcıları</h2>
      <p>
        Bazı durumlarda, web sitemizin işlevselliğini artırmak veya analitik hizmetleri sağlamak amacıyla üçüncü taraf hizmet sağlayıcıları ile çalışabiliriz. Bu hizmet sağlayıcılar, yalnızca belirli bir amaca yönelik olarak verilere erişim sağlayabilir ve bu verileri gizlilik politikamıza uygun şekilde kullanmak zorundadır.
      </p>

      <h2>5. Kullanıcı Hakları</h2>
      <p>
        Kullanıcılar, aşağıdaki haklara sahiptir:
      </p>
      <ul>
        <li>Kişisel verilerine erişim talep etme,</li>
        <li>Kişisel verilerinin düzeltilmesini veya silinmesini talep etme,</li>
        <li>Verilerinin işlenmesine itiraz etme.</li>
      </ul>

      <h2>6. Gizlilik Politikasındaki Değişiklikler</h2>
      <p>
        CertisCode, bu gizlilik politikasını zaman zaman güncelleyebilir. Herhangi bir değişiklik yapıldığında, kullanıcılarımıza e-posta yoluyla veya web sitemizde bir bildirim aracılığıyla bilgi vereceğiz.
      </p>

      <h2>7. İletişim Bilgileri</h2>
      <p>
        Gizlilik politikamızla ilgili sorularınız veya endişeleriniz varsa, lütfen bizimle iletişime geçin:
      </p>
      <p>
        <strong>E-posta:</strong> info@certiscode.com <br />
        <strong>Telefon:</strong> +90 543 289 69 41 <br />
        <strong>Adres:</strong> Antalya, Türkiye
      </p>
    </div>
  );
};

export default PrivacyPolicy;
