import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaPhoneAlt, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import '../styles/pages/contact.css';
import '../styles/pages/pagesHeaderTag.css';

const Contact = () => {
  const [contactInfo, setContactInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: ''
  });

  // Doğrulama fonksiyonu
  const validateInput = (name, value) => {
    switch (name) {
      case 'first_name':
      case 'last_name':
        return /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/.test(value);
      case 'phone_number':
        return /^[0-9\s+]*$/.test(value);
      case 'message':
        return value.length <= 1000;
      default:
        return true;
    }
  };

  useEffect(() => {
    const loadContactInfo = async () => {
      try {
        const response = await axios.get("https://www.certiscode.com/api/contactinfo/");
        if (response.data && response.data.length > 0) {
          setContactInfo(response.data[0]);
        } else {
          setError('İletişim bilgileri bulunamadı.');
        }
        setLoading(false);
      } catch (err) {
        setError('Sunucuya bağlanılamadı.');
        setLoading(false);
      }
    };

    loadContactInfo();
  }, []);

  // Form gönderme işlemi
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.message.length > 1000) {
      Swal.fire('Hata', 'Mesaj 1000 karakterden fazla olamaz.', 'error');
      return;
    }

    try {
      const response = await axios.post('https://www.certiscode.com/api/contact/', formData);
      Swal.fire('Başarılı!', response.data.message, 'success');
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        message: ''
      });
    } catch (err) {
      console.error(err);
      Swal.fire('Hata', 'Mesaj gönderilirken bir hata oluştu.', 'error');
    }
  };

  // Form alanlarını güncelleme
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (validateInput(name, value)) {
      setFormData({
        ...formData,
        [name]: value
      });
    } else {
      if (name === 'first_name' || name === 'last_name') {
        Swal.fire('Hata', `${name === 'first_name' ? 'İsim' : 'Soyisim'} sadece harflerden oluşmalıdır.`, 'warning');
      } else if (name === 'phone_number') {
        Swal.fire('Hata', 'Telefon numarası sadece sayılardan oluşmalıdır.', 'warning');
      }
    }
  };

  if (loading) {
    return <div className="loading">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="contact-page page">
      <div className="page-header">
        <h1>İletişim</h1>
      </div>

      <div className="contact-container">
        {/* Sol Bölüm: İletişim Bilgileri */}
        <div className="contact-info">
          <div className="info-card" onClick={() => window.location.href = `tel:${contactInfo.phone_number}`}>
            <FaPhoneAlt className="contact-icon" />
            <h3>Telefon</h3>
            <p>{contactInfo.phone_number}</p>
          </div>
          <div className="info-card" onClick={() => window.location.href = `https://wa.me/${contactInfo.phone_number.replace(/\s/g, '')}`}>
            <FaWhatsapp className="contact-icon" />
            <h3>WhatsApp</h3>
            <p>{contactInfo.phone_number}</p>
          </div>
          <div className="info-card" onClick={() => window.location.href = `mailto:${contactInfo.email}`}>
            <FaEnvelope className="contact-icon" />
            <h3>E-posta</h3>
            <p>{contactInfo.email}</p>
          </div>
        </div>

        {/* Sağ Bölüm: Geri Arama Formu */}
        <div className="contact-form">
          <h2>Biz Sizi Arayalım</h2>
          <form onSubmit={handleSubmit}>
            <input 
              type="text" 
              name="first_name"
              placeholder="Adınız" 
              value={formData.first_name}
              onChange={handleChange}
              required 
            />
            <input 
              type="text" 
              name="last_name"
              placeholder="Soyadınız" 
              value={formData.last_name}
              onChange={handleChange}
              required 
            />
            <input 
              type="email" 
              name="email"
              placeholder="E-posta Adresiniz" 
              value={formData.email}
              onChange={handleChange}
              required 
            />
            <input 
              type="tel" 
              name="phone_number"
              placeholder="Telefon Numaranız" 
              value={formData.phone_number}
              onChange={handleChange}
              required 
            />
            <textarea 
              name="message"
              placeholder="Mesajınız" 
              rows="4" 
              value={formData.message}
              onChange={handleChange}
              required 
            ></textarea>
            <button type="submit">Gönder</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
