import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Contact = () => {
  const [contactInfo, setContactInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadContactInfo = async () => {
      try {
        const response = await axios.get("https://www.certiscode.com/api/contactinfo/");
        if (response.data && response.data.length > 0) {
          // İlk iletişim bilgisini alalım
          setContactInfo(response.data[0]);
        } else {
          setError('İletişim bilgileri bulunamadı.');
        }
        setLoading(false);
      } catch (err) {
        setError('Sunucuya bağlanılamadı.');
        setLoading(false);
      }
    };

    loadContactInfo();
  }, []);

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {/* İletişim bilgilerini göster */}
      <p>Telefon: {contactInfo.phone_number}</p>
      <p>E-posta: {contactInfo.email}</p>
    </div>
  );
};

export default Contact;
