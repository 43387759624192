import React from 'react';
import Header from './Header';
import Contact from './Contact';

function AdminPage() {
  return (
    <div className="admin-page">
      <Header />
      <Contact />
    </div>
  );
}

export default AdminPage;
