import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate hook'u import edildi
import "../styles/services.css"; // CSS dosyası

import webDesignImage from "../assets/webtasarim.png";
import startupImage from "../assets/startup.png";
import kurumsalImage from "../assets/Kurumsal.png";
import eticaretImage from "../assets/eticaret.png";
import iletisimImage from "../assets/iletisim.png";
import webdevImage from "../assets/webdevelopment.png";

const Services = () => {
  const navigate = useNavigate(); // useNavigate hook'u tanımlandı

  const handleCardClick = (route) => {
    navigate(route);
    window.scrollTo(0, 0); // Yönlendirme yapıldığında sayfanın en üstüne kaydırır
  };

  useEffect(() => {
    const cards = document.querySelectorAll(".service-card");

    cards.forEach((card) => {
      const handleMouseMove = (e) => {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left - rect.width / 2;
        const y = e.clientY - rect.top - rect.height / 2;
        card.style.transform = `rotateY(${x / 10}deg) rotateX(${-y / 10}deg)`;
      };

      const handleMouseLeave = () => {
        card.style.transform = "rotateY(0) rotateX(0)";
      };

      card.addEventListener("mousemove", handleMouseMove);
      card.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        card.removeEventListener("mousemove", handleMouseMove);
        card.removeEventListener("mouseleave", handleMouseLeave);
      };
    });
  }, []);

  return (
    <>
      <section className="services">
        <div className="services-section">
          <div
            className="service-card"
            style={{ backgroundImage: `url(${kurumsalImage})` }}
            onClick={() => handleCardClick('/hizmetler')}
          ></div>
          <div
            className="service-card"
            style={{ backgroundImage: `url(${eticaretImage})` }}
            onClick={() => handleCardClick('/hizmetler')}
          ></div>
        </div>
      </section>
      <div className="services-paragraph-container">
          <div className="services-paragraph">
            <h2>Mobil Uyumlu ve Hızlı Yüklenen Web Çözümleri</h2>
            <p>
              Certis Code, mobil uyumluluğun ve hızın, dijital başarıda kritik
              bir rol oynadığını bilir. Her cihazda sorunsuz çalışan ve hızlı
              yükleme süreleri ile kullanıcı deneyimini en üst seviyeye taşıyan
              web siteleri oluşturuyoruz. Optimize edilmiş içerik ve kod
              yapılarımız sayesinde, siteniz hem mobil hem de masaüstü
              cihazlarda mükemmel performans gösterir. Ziyaretçilerinize
              kesintisiz ve keyifli bir deneyim sunarak, markanızı dijital
              dünyada güçlü bir şekilde temsil ediyoruz.
            </p>
            <h2>SEO Dostu Altyapılarla Daha Fazla Görünürlük</h2>
            <p>
              Dijital dünyada rekabetin yoğun olduğu bir dönemde, arama
              motorlarında üst sıralarda yer almak büyük önem taşıyor. Certis
              Code olarak, SEO uyumlu altyapılar ve içerik stratejileri ile
              sitenizin görünürlüğünü artırıyoruz. Doğru anahtar kelimeler,
              hızlı sayfa yükleme süreleri ve kullanıcı dostu yapılar ile hedef
              kitlenize daha kolay ulaşmanızı sağlıyoruz. Markanızı bir adım öne
              taşımak ve daha geniş bir kitleye ulaşmak için SEO stratejilerini
              projelerinize entegre ediyoruz.
            </p>
          </div>
        </div>
      <section className="services">
        <div className="services-section">
          <div
            className="service-card"
            style={{ backgroundImage: `url(${webDesignImage})` }}
            onClick={() => handleCardClick('/hizmetler')}
          ></div>
          <div
            className="service-card"
            style={{ backgroundImage: `url(${startupImage})` }}
            onClick={() => handleCardClick('/hizmetler')}
          ></div>
        </div>
      </section>
      <div className="services-paragraph-container">
          <div className="services-paragraph">
            <h2>Özelleştirilmiş Web Tasarım ve Geliştirme</h2>
            <p>
              Certis Code, her markanın benzersiz olduğunu ve standart
              çözümlerden daha fazlasını hak ettiğini bilir. İşletmenizin
              ihtiyaçlarına özel olarak geliştirilmiş web siteleri ile dijital
              dünyada fark yaratıyoruz. Hem estetik hem de işlevselliği bir
              arada sunarak markanıza özel bir dijital varlık oluşturuyoruz.
              Özelleştirilebilir yapı taşlarımız ile projelerinizi hayal
              ettiğiniz gibi şekillendiriyoruz.
            </p>
            <h2>Güvenlik ve Veri Koruma Önceliğimiz</h2>
            <p>
              Dijital güvenlik, her geçen gün daha fazla önem kazanıyor. Certis
              Code olarak, projelerinizin güvenliğini en üst düzeyde sağlamak
              için çalışıyoruz. Güncel güvenlik protokolleri ve veri koruma
              yöntemlerimizle, sitenizi dış tehditlere karşı koruyor ve
              kullanıcı verilerinin gizliliğini sağlıyoruz. Güvenli bir dijital
              varlık ile ziyaretçilerinize huzurla kullanabilecekleri bir
              platform sunuyoruz.
            </p>
          </div>
        </div> 
      <section className="services">
        <div className="services-section">
          <div
            className="service-card"
            style={{ backgroundImage: `url(${iletisimImage})` }}
            onClick={() => handleCardClick('/hizmetler')}
          ></div>
          <div
            className="service-card"
            style={{ backgroundImage: `url(${webdevImage})` }}
            onClick={() => handleCardClick('/hizmetler')}
          ></div>
        </div>
      </section>
    </>
  );
}

export default Services;
