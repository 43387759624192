import React from 'react';
import '../styles/infobar.css';

const InfoBar = () => {
  return (
    <div className="info-bar">
      <p>Bizimle İletişime Geçin: +90 543 289 69 41 | info@certiscode.com</p>
      <a href="https://wa.me/905432896941" target="_blank" rel="noopener noreferrer" className="offer-btn">
        Teklif Al
      </a>
    </div>
  );
};

export default InfoBar;
