import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Varsayılan SEO verileri
const defaultSeoData = {
  meta_title: 'Varsayılan Başlık',
  meta_description: 'Varsayılan Açıklama',
  meta_keywords: 'Varsayılan Anahtar Kelimeler',
  meta_robots: 'index, follow',
};

const SEO = ({ pageName }) => {
  const [seoData, setSeoData] = useState(defaultSeoData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSEOData = async () => {
      setLoading(true);
      try {
        // API'den SEO verilerini çekme
        const response = await axios.get('https://www.certiscode.com/api/seo-content/');
        const pageSEO = response.data.find(seo => seo.page.toLowerCase() === pageName.toLowerCase());

        if (pageSEO) {
          setSeoData({
            meta_title: pageSEO.meta_title || defaultSeoData.meta_title,
            meta_description: pageSEO.meta_description || defaultSeoData.meta_description,
            meta_keywords: pageSEO.meta_keywords || defaultSeoData.meta_keywords,
            meta_robots: pageSEO.meta_robots || defaultSeoData.meta_robots,
          });
        } else {
          setError(`SEO verisi bulunamadı: ${pageName}`);
        }
      } catch (error) {
        console.error('SEO verileri alınırken hata oluştu:', error);
        setError('SEO verileri alınırken bir hata oluştu.');
      } finally {
        setLoading(false);
      }
    };

    fetchSEOData();
  }, [pageName]);

  // Yükleme veya hata durumunda hiçbir şey döndürme
  if (loading || error) return null;

  return (
    <Helmet>
      {/* Meta etiketleri */}
      <title>{seoData.meta_title}</title>
      <meta name="description" content={seoData.meta_description} />
      <meta name="keywords" content={seoData.meta_keywords} />
      <meta name="robots" content={seoData.meta_robots} />

      {/* Open Graph etiketleri */}
      <meta property="og:title" content={seoData.meta_title} />
      <meta property="og:description" content={seoData.meta_description} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="tr_TR" />
    </Helmet>
  );
};

export default SEO;
