import React from "react";
import '../styles/mainparagraf.css';
import exampleImage from '../assets/certiscode.png'; // Replace with your actual image path

const ServicesParagraph = () => {
  return (
    <div className="services-paragraph-container">
      <div className="services-paragraph-image">
        <img src={exampleImage} alt="Certis Code Illustration" />
      </div>
      <div className="services-paragraph">
        <h2>İşinizi Geleceğe Taşıyan Özgün Çözümler</h2>
        <p>
          Certis Code, müşteri odaklı yaklaşımıyla dijital dünyada fark yaratmanıza yardımcı olur. Yalnızca estetik açıdan etkileyici değil, aynı zamanda kullanıcı deneyimini ön planda tutan web siteleri tasarlıyoruz. Her projede, esneklik ve müşteri taleplerine hızlı yanıt verme ilkesiyle çalışarak, işinizi geleceğe taşımayı hedefliyoruz. Güçlü altyapımız ve uzman ekibimizle, projenizin her aşamasında yanınızdayız.
        </p>
        <h2>Dijital Başarının Anahtarı: Doğru Strateji ve Kalite</h2>
        <p>
          Certis Code olarak, uygun fiyatlı ve yüksek kaliteli hizmetlerimizle işinizi dijital dünyada ileriye taşımayı taahhüt ediyoruz. Modern tasarım trendlerini ve SEO dostu yapıları bir araya getirerek markanızın çevrimiçi görünürlüğünü artırıyoruz. Şeffaf ve güvenilir bir hizmet anlayışıyla, projelerinizi zamanında ve beklentilerinizi karşılayacak şekilde tamamlıyoruz. Sizin başarınız, bizim önceliğimizdir.
        </p>
      </div>
    </div>
  );
};

export default ServicesParagraph;
