import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import axios from 'axios';
import '../styles/footer.css';

const Footer = () => {
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    // API'den logo verisini çekmek için istek
    const fetchSiteSettings = async () => {
      try {
        const response = await axios.get('https://www.certiscode.com/api/sitesettings/');
        if (response.data && response.data.length > 0) {
          setLogoUrl(response.data[0].logo); // Logo URL'sini ayarla
        }
      } catch (error) {
        console.error('Logo verisi alınırken hata oluştu:', error);
      }
    };

    fetchSiteSettings();
  }, []);

  return (
    <>
      <section className="footer-section">
        {/* Sol Grid (Hızlı Menüler) */}
        <div className="footer-quick-links">
          <h4>Hızlı Menü</h4>
          <Link to="/">Ana Sayfa</Link>
          <Link to="/hakkimizda">Hakkımızda</Link>
          <Link to="/hizmetler">Hizmetler</Link>
          <Link to="/referanslar">Referanslarımız</Link>
          <Link to="/iletisim">İletişim</Link>
        </div>

        {/* Orta Grid (Logo ve İletişim) */}
        <div className="footer-middle">
          <div className="footer-logo">
            {logoUrl ? (
              <img src={logoUrl} alt="CertisCode Logo" className="footer-logo-img" />
            ) : (
              <span className="logo-text">CertisCode Logo</span>
            )}
          </div>
          <div className="footer-contacts">
            <a href="mailto:info@certiscode.com">info@certiscode.com</a>
            <a href="tel:+123456789">+90 543 289 69 41</a>
          </div>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </div>
        </div>

        {/* Sağ Grid (Boş alan, gerektiğinde içerik eklenebilir) */}
        <div className="footer-empty"></div>
      </section>

      <footer className="footer">
        <p>
          © 2024 CertisCode | <Link to="/gizlilik-ve-cerez-politikasi">Gizlilik ve Çerez Politikası</Link> | Tüm Hakları Saklıdır.
        </p>
      </footer>
    </>
  );
};

export default Footer;
